import {wrapDrawSVG} from '../vendor/jquery.drawsvg';
import {wrapSlickJS} from '../vendor/slick';
import IMask from 'imask';
import { appendEmptyAltsToImagesWithoutAlt, initializePhoneNumberAccessibility } from '@tranzact/tzt-a11y';
import "../vendor/select2";

/* ==============================================================================================
 * Tranzact.net Scripts
 *
 * This file contains a router that runs functions based on the current page. The routes are in
 * the Routes object and each route is an object that contains all the methods that will be ran
 * when the Router.loadEvents method is called. Below is the hierarchy in which the file is
 * structured
 *
 * 1. Router
 *      1.1 camelCase
 *      1.2 loadEvents
 * 2. Routes
 *      2.1 general
 *          2.1.1 equalHeight
 *          2.1.2 menuToggle
 *          2.1.3 preloader
 *          2.1.4 removeVideo
 *          2.1.5 scrollArrowFade
 *          2.1.6 scrollTrigger
 *          2.1.7 smoothScroll
 *          2.1.8 stickyHeader
 *          2.1.9 touch
 *      2.2 home
 *          2.2.1 statisticsCounter
 *          2.2.2 slickSlider
 *      2.3 whoWeAre
 *          2.3.1 slickSlider
 *      2.4 whatWeDo
 *          2.4.1 servicesToggle
 *      2.5 leadership
 *          2.5.1 modal
 *      2.6 contact
 *          2.6.1 contactForm
 *      2.7 Job Fair form
 *          2.7.1 contactFairForm
  * 3. Initialization
 * ============================================================================================== */


// Wrapping everything in an IIFE to hide all functions and variables
// from being accessed or clashing with any third party javascript

(( window, document, $ ) => {

/* ==============================================================================================
 * 1. Router
 *
 * Invokes functions based on the body classes. This minimizes the amount of javascript fired
 * since only functions that are absolutely necessary for the current page are invoked
 * ============================================================================================== */
wrapDrawSVG($);
wrapSlickJS($);
appendEmptyAltsToImagesWithoutAlt();
initializePhoneNumberAccessibility();

const Router = {

    camelCase: string => {

        // Converting the hyphenated string to a camel
        // cased string

        if ( string.indexOf( 'page-' ) !== -1 ) {

            return string.replace( 'page-', '' ).replace( /-([a-z])/g, g => {
                return g[ 1 ].toUpperCase()
            })

        }

    },
    loadEvents: function () {

        // Invoking all of the methods in the general route
        // since these methods will be needed for every page

        for ( let method in Routes[ 'general' ] ) {

            if ( Routes[ 'general' ].hasOwnProperty( method ) ) {
                Routes[ 'general' ][ method ]()
            }

        }

        // Invoking all of the methods that are specific to
        // the body class of the current page

        document.body.className
            .split( /\s+/ )
            .map( this.camelCase )
            .forEach( className => {

                const route = Routes[ className ]

                if ( route && route !== '' ) {
                    for ( let method in route ) {
                        if ( route.hasOwnProperty( method ) ) {
                            route[ method ]()
                        }
                    }
                }

            })

    }

}


/* ==============================================================================================
 * 2. Routes
 *
 * Each object is a route that contains the methods necessary for that particular page. The
 * general route contains methods that will need to run on every page
 * ============================================================================================== */

 const Routes = {

    general: {

        onlyAlphabets(e, t, noSpace) {
            try {
                var charCode;
                if (window.event) {
                    charCode = window.event.keyCode;
                } else if (e) {
                    charCode = e.which;
                } else {
                    return true;
                }
                if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || (charCode == 0 || charCode == 32)) {
                    if (noSpace) {
                        if (charCode == 0 || charCode == 32) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e.message);
            }
        },

        equalHeight: () => {

            // Making all divs matching the selector height equal

            const makeEqualHeight = () => {

                const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                let calcHeight

                calcHeight = ( elements, callback ) => {

                    let currentTallest = 0
                    let currentRowStart = 0
                    let currentDiv
                    let rowDivs = []
                    let element
                    let topPosition = 0
                    let i = 0

                    $( elements ).each( function () {

                        i++

                        element = $( this )
                        element.outerHeight( 'auto' )

                        rowDivs.push( element )
                        currentTallest = ( currentTallest < element.outerHeight() ) ? ( element.outerHeight() ) : ( currentTallest )

                        for ( currentDiv = 0; currentDiv < rowDivs.length; currentDiv++ ) {
                            rowDivs[ currentDiv ].outerHeight( currentTallest )
                        }

                        if ( i == $( elements ).length && typeof callback == 'function' ) {
                            callback()
                        }

                    })

                }

                // Small Tablet Breakpoint

                if ( windowWidth >= 650 ) {

                    calcHeight( '.leadership-member .is--executives', () => {
                        $( '.leadership-member .is--executives' ).addClass( 'vertical-align' )
                    })

                } else {

                    $( '.leadership-member .link-box' ).removeAttr( 'style' ).removeClass( 'vertical-align' )

                }

                // Desktop Breakpoint

                if ( windowWidth >= 1000 ) {

                    calcHeight( '.office .info', () => {
                        $( '.office .info' ).addClass( 'vertical-align' )
                    })

                } else {

                    $( '.office .info' ).removeAttr( 'style' ).removeClass( 'vertical-align' )

                }

                if ( windowWidth < 1000 && windowWidth >= 650 ) {

                    calcHeight( '.page-what-we-do .navigation li', () => {
                        $( '.page-what-we-do .navigation li' ).addClass( 'vertical-align' )
                    })

                } else {

                    $( '.page-what-we-do .navigation li' ).removeAttr( 'style' ).removeClass( 'vertical-align' )

                }

                // Large Desktop Breakpoint

                if ( windowWidth >= 1250 ) {

									if ( !$('.page-leadership') ) {
										calcHeight('.modal-content > *', () => {
											$('.modal-content > *').addClass('vertical-align')
										})
									}
                } else {

                    $( '.modal-content > *' ).removeAttr( 'style' ).removeClass( 'vertical-align' )

                }

            }

            makeEqualHeight()

            $( window ).resize( () => {
                makeEqualHeight()
            })

        },
        menuToggle: () => {

            // Opening/closing the navigation menu

            $( 'body' ).on( 'click', '.menu-toggle-container, .canvas-cover', () => {
                $( 'body' ).toggleClass( 'nav-open' )
            })

            $( 'body' ).on( 'click', '#close-menu', () => {
                $( 'body' ).toggleClass( 'nav-open' )
            })

        },
        preloader: () => {

            // Waiting on all of the elements to load and
            // then running preloaderDoneLoading()
            const home = $( '.home' );

            const preloaderDoneLoading = () => {

                // Removing the preloader

                $( 'body' ).removeClass( 'loading' ).addClass( 'loader-animating' )
                setTimeout( () => {
                    $( 'body' ).removeClass( 'loader-animating' ).addClass( 'done-loading' )
                }, 1100 )

            }

            const preloaderStartLoading = callback => {

                // Showing the preloader

                home.removeClass( 'done-loading' ).addClass( 'start-loading' )
                setTimeout( () => {
                    home.addClass( 'loader-animating-out' )
                }, 100 )
                setTimeout( callback, 1100 )

            }

            let contentLoaded = false
            home.addClass( 'loading' )

            home.imagesLoaded = () => {

                if ( contentLoaded ) {
                    preloaderDoneLoading()
                }

                contentLoaded = true

            };

            $( window ).on( 'load', () => {

                if ( contentLoaded ) {
                    preloaderDoneLoading()
                }

                contentLoaded = true

                setTimeout( () => {
                    preloaderDoneLoading()
                }, 1000 )

                // Preventing all same-site links from immediately
                // going to the next page and showing the preloader
                // for a seemingly seamless transition
                /* $( 'a[href^="' + tz_object.url + '"]' ).on( 'click', function ( ev ) {

                     ev.preventDefault()

                     if ($(this).parent().attr('id') == 'menu-item-37') {

                         window.open($(this).attr('href'));
                         return false;
                     }

                     const href = $( this ).attr( 'href' )

                     preloaderStartLoading( () => {

                         setTimeout( () => {
                             window.location.href = href
                         }, 15 )

                     })

                 })*/

            })
        },
        removeVideo: () => {

            // Removing HTML5 video on mobile since
            // it does not allow for autoplay

            const video = $( '#herovideo' )
            const isMobile = {

                android: function() {
                    return navigator.userAgent.match(/Android/i)
                },
                blackberry: function() {
                    return navigator.userAgent.match(/BlackBerry/i)
                },
                ios: function() {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
                },
                opera: function() {
                    return navigator.userAgent.match(/Opera Mini/i)
                },
                windows: function() {
                    return navigator.userAgent.match(/IEMobile/i)
                },
                any: function() {
                    return ( isMobile.android() || isMobile.blackberry() || isMobile.ios() || isMobile.opera() || isMobile.windows() )
                }

            }

            if ( isMobile.any() ) {
                video.remove()
            }

        },
        scrollArrowFade: () => {

            // Fading out hero arrow on scroll

            const fadeArrow = () => {

                const scroll = $( window ).scrollTop()

                if ( scroll > 50 ) {
                    $( '.hero .icon-arrow-down' ).addClass( 'fade-out' )
                } else {
                    $( '.hero .icon-arrow-down' ).removeClass( 'fade-out' )
                }

            }

            fadeArrow()

            $( window ).scroll( () => {
                fadeArrow()
            })

        },
        scrollTrigger: () => {

            // Triggering the entrance of elements

            const trigger = () => {

                const elements = {
                    curtain: {
                        element: $( '.curtain-wrap' )
                    }
                }

                Object.keys( elements ).forEach( key => {

                    const element = elements[ key ].element
                    let elementTop

                    if ( element.length > 0 ) {

                        element.each( function () {

                            elementTop = $( this ).offset().top - $( window ).height() + 200

                            if ( $( window ).scrollTop() >= elementTop ) {
                                $( this ).addClass( 'triggered' );
                            } /*else {
                                $( this ).removeClass( 'triggered' );
                            }*/

                        })

                    }

                })

            }

            trigger()

            $( window ).scroll( () => {
                trigger()
            })

        },
        touch: () => {

            // Making sure all of the cool hover animations are still
            // in tact on mobile devices.

            var elements = [
                $( '.button' ),
                $( '.service-background' ),
                $( '.who-we-are-section .half-inner' ),
                $( '.cta .half-inner' ),
                $( '.footer .social' ),
                $( '.footer .contact' ),
                $( '.leadership-member' ),
                $( '.more-leadership-member' ),
                $( '.job .job-meta' )
            ]

            $.each( elements, ( index, element ) => {

                element.bind( 'touchstart', function () {
                    $( this ).addClass( 'hovered' )
                }).bind( 'touchend', function () {
                    $( this ).removeClass( 'hovered' )
                })

            })

        },
				tabs: () => {

					const tabsItem = $('.services__tabs li');

					tabsItem.on('click', 'a', function(e){
							e.preventDefault();
							const _self = $(this), target = _self.attr('href');

							tabsItem.each(function(){
									$(this).removeClass('active');
							});

							$('.content__tab').each(function(){
									$(this).removeClass('open');
							});
							_self.parent().addClass('active');

							$(target).addClass('open');
					});

			},
			slickSlider: () => {

				const values = $( '.slider-component' )
				const slidesLargeDektop = values.data('slides-large-desktop');
				const slidesDektop = values.data('slides-desktop');
				const slidesTablet = values.data('slides-tablet');
				const slidesMobile = values.data('slides-mobile');

				if ( values.length > 0 ) {

						values.slick({
								arrows: false,
								centerMode: true,
								dots: true,
								easing: 'easeInOutQuart',
								customPaging : function(slider, i) {
										var slideNumber = (i + 1),
												totalSlides = slider.slideCount;
										return '<a class="dot" role="button" title="'+ slideNumber + '/' + totalSlides + '"></a>';
								},
								nextArrow: '<span class="icon icon-arrow-right-circle"></span>',
								prevArrow: '<span class="icon icon-arrow-left-circle"></span>',
								mobileFirst: true,
								slidesToScroll: 1,
								slidesToShow: slidesMobile ? slidesMobile : 1,
								speed: 400,
								responsive: [
										{
												breakpoint: 1279,
												settings: {
														arrows: true,
														dots: false,
														slidesToShow: slidesLargeDektop ? slidesLargeDektop : 5
												}
										},
										{
												breakpoint: 1024,
												settings: {
														arrows: true,
														dots: false,
														slidesToShow: slidesDektop ? slidesDektop : 3
												}
										},
										{
												breakpoint: 767,
												settings: {
														arrows: true,
														dots: false,
														slidesToShow: slidesTablet ? slidesTablet : 1
												}
										}
								]
						})

				}

			},
			membercarePopup: () => {
				const popup = document.getElementById('membercare-popup');
				if (popup){
					popup.style.bottom = '0';
				}

				const toggleButton = document.getElementById('popup-toggle');
				const closeButton = document.getElementById('popup-close');


				toggleButton.addEventListener('click', function() {
					popup.classList.toggle('open');
				});

				closeButton.addEventListener('click', function() {
					popup.classList.remove('open');
					toggleButton.style.display = 'block';  // Show the open toggle button when closed
				});


			}

    },
    home: {

        statisticsCounter: () => {

            /*
            * Animate numbers and svg for the first slider when the scroll is offset top
            */
            const sliderSection = $('.home .section__slide');
            const numbersToAnimate = sliderSection.find('.counter');
            const svgsToAnimate = sliderSection.find('svg');

            /* animate numbers options
            const options = {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '',
                prefix: '',
                suffix: '',
                startVal: 0
            };*/

            // define Initializers
            // let initNumbersAnimate = [];
            let initSvgsAnimate = [];
            let first = true;

            /* Initialize each counter
            numbersToAnimate.each(function(i, elm){
                const _self = $(elm);
                initNumbersAnimate[i] = new CountUp(_self.attr('id'), 0, $(numbersToAnimate[i]).attr('data-count'), 0, 2, options);
            });
            */

            // Initialize each svg
            svgsToAnimate.each(function(i, elm) {
                const _self = $(elm);
                initSvgsAnimate[i] = _self.drawsvg({
                    stagger: 3
                });
            });

            // Slider
            const slickSlider = sliderSection.slick({
                arrows: false,
                autoplay: true,
                autoplaySpeed: 7000,
                cssEase: 'linear',
                dots: false,
                draggable: false,
                fade: true,
                infinite: true,
                pauseOnHover: false
            });

            if ( numbersToAnimate.length > 0 ) {

                const _window = $(window);
                let scrollTop = _window.scrollTop();
                const offset = sliderSection.offset().top;

                if ( scrollTop >= (Math.round(offset) - _window.height()) && first) {
                    //initNumbersAnimate[0].start();
                    initSvgsAnimate[0].drawsvg('animate');
                    first = false;
                }

                _window.on('scroll', function() {
                    scrollTop = $(this).scrollTop();
                    if ( scrollTop > (Math.round(offset) - _window.height()) && first) {
                        //initNumbersAnimate[0].start();
                        initSvgsAnimate[0].drawsvg('animate');
                        first = false;
                    }
                });

            }

            // when slides changes
            slickSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                if(!first) {
                    initSvgsAnimate[nextSlide].css('opacity', 0);
                }
            });
            slickSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){
                if (!first) {
                    //initNumbersAnimate[currentSlide].reset();
                    //initNumbersAnimate[currentSlide].start();
                    initSvgsAnimate[currentSlide].css('opacity', 1).drawsvg('animate');
                }
            });

        },

        modal: () => {

            // Opening/closing the leadership modal and
            // navigating from one to the other

            const memberModals = $( '.modal-content-small' );

            // Opening the modal

            $( '#press-release' ).on( 'click', function ( ev ) {

                ev.preventDefault();

                // Hiding the current member modal and showing the
                // the new active member modal before the modal
                // wrapper is shown

                memberModals.each( function () {
                    $( this ).removeClass( 'show' );
                });



                $( $(this).attr('href') ).addClass( 'show' );

                // Showing the modal wrapper and running equalheight()
                // so that the image and content are vertically
                // aligned

                $( 'body' ).addClass( 'modal-open' );
                //Routes.general.equalHeight();

                // Pausing for a split second for the modal is displayed
                // to then fade it in

                setTimeout( () => {
                    $( 'body' ).addClass( 'modal-fade-in' )
            }, 100 )

            })

            // Closing the modal

            $( '.blue-modal .close-modal' ).on( 'click', () => {

                // Fading out the modal wrapper

                $( 'body' ).removeClass( 'modal-fade-in' );

                // Pausing until the fade transition is done to then
                // hide the modal wrapper

                setTimeout( () => {
                    $( 'body' ).removeClass( 'modal-open' );
                }, 300 )

            })

        },

    },
    whoWeAre: {



    },
    whatWeDo: {

        servicesToggle: () => {

            const navTrigger = $( '.navigation li' )

            if ( window.location.hash ) {

                const hash = window.location.hash
                const target = hash.replace( '#', '' )

                window.scrollTo( 0, 0 )

                $( '.services-section' ).attr( 'id', target )
                    .removeClass( 'marketing' )
                    .removeClass( 'technology' )
                    .removeClass( 'sales' )
                    .addClass( target )

            }

            navTrigger.on( 'click', function () {

                const service = $( this ).attr( 'data-service' )

                $( '.services-section' ).removeClass( 'marketing' )
                    .removeClass( 'technology' )
                    .removeClass( 'sales' )
                    .removeAttr( 'id' )
                    .attr( 'id', service )
                    .addClass( service )

            })

        }

    },
    leadership: {

        tabs: () => {

            $('.leadership-tabs').on('click', '.leadership-tab', function(e){
                e.preventDefault();
                var _self = $(this), target = _self.attr('href');

                $('.leadership-tabs .leadership-tab').each(function(){
                    $(this).removeClass('active');
                });
                $('.leadership-section .leadership-content').each(function(){
                    $(this).removeClass('open');
                });
                _self.addClass('active');
                $(target).addClass('open');

                $('.leadership-tabs select').val(target).trigger('change');
            });

        },

        select: () => {
            // Initialize Select2
            const leadershipSelect = $('#leadership-sections');

            leadershipSelect.select2({
                minimumResultsForSearch: -1,
                width: '100%'
            });

            leadershipSelect.on('select2:select', function (e) {
                var target = e.params.data.id;

                $('.leadership-tabs .leadership-tab').each(function(){
                    $(this).removeClass('active');
                });

                $('.leadership-section .leadership-content').each(function(){
                    $(this).removeClass('open');
                });
                $('a[href="'+target+'"]').addClass('active');
                $(target).addClass('open');
            });
        },

        navigationSection() {

            const navigation =  document.getElementById('navigation-slide');
            const sections = document.getElementById('leadership-sections');

            // create list
            if ( sections.length > 0 ) {
                for( let i = 0; i < sections.length; i++ ) {
                    var li = document.createElement('li');
                    if ( i === 0 ) {
                        li.setAttribute('class', 'active');
                    }
                    li.setAttribute('id', sections.options[i].value);
                    navigation.appendChild(li);
                }
            }

            // Navigation

            const navItems = Array.prototype.slice.call(document.querySelectorAll('body #navigation-slide li'),0);
            const contents = Array.prototype.slice.call(document.querySelectorAll('.leadership-content'),0);

            navItems.forEach( (elm) => {
                elm.onclick = (e) => {
                    const target = e.target.getAttribute('id');

                    // Activate current Item
                    for( let i = 0; i < navItems.length; i++ ) {
                        navItems[i].classList.remove('active');
                    }
                    e.target.classList.add('active');
                    $('.leadership-tabs select').val(target).trigger('change');

                    // Show content section
                    contents.forEach((content) => {
                        content.classList.remove('open');

                        if ( target === `#${content.getAttribute('id')}`) {
                            content.classList.add('open');
                        }

                    });
                }
            });

        },

        modal: () => {

            // Opening/closing the leadership modal and
            // navigating from one to the other

            const memberModals = $( '.modal-content' )

            // Opening the modal

            $( '.leadership-modal' ).on( 'click', function ( ev ) {

                ev.preventDefault()
                const member = $( this ).attr( 'data-name' )

                // Hiding the current member modal and showing the
                // the new active member modal before the modal
                // wrapper is shown

                memberModals.each( function () {
                    $( this ).removeClass( 'show' )
                })

                $( '.modal-content#' + member ).addClass( 'show' )

                // Showing the modal wrapper and running equalheight()
                // so that the image and content are vertically
                // aligned

                $( 'body' ).addClass( 'modal-open' )
                Routes.general.equalHeight()

                // Pausing for a split second for the modal is displayed
                // to then fade it in

                setTimeout( () => {
                    $( 'body' ).addClass( 'modal-fade-in' )
                }, 100 )

            })

            // Closing the modal

            $( '.modal-outer-wrap .close-modal' ).on( 'click', () => {

                // Fading out the modal wrapper

                $( 'body' ).removeClass( 'modal-fade-in' )

                // Pausing until the fade transition is done to then
                // hide the modal wrapper

                setTimeout( () => {
                    $( 'body' ).removeClass( 'modal-open' )
                }, 300 )

            })

            // Navigating throughout the modal wrapper

            $( '.modal-outer-wrap .left-arrow' ).on( 'click', () => {

                let nextIndex = Number( $( '.modal-content.show' ).attr( 'data-index' ) ) - 1

                if ( nextIndex < 1 ) {
                    nextIndex = memberModals.length
                }

                memberModals.each( function () {
                    $( this ).removeClass( 'show' )
                })

                $( '.modal-content[data-index="' + nextIndex + '"]' ).addClass( 'show' )

            })

            $( '.modal-outer-wrap .right-arrow' ).on( 'click', () => {

                let nextIndex = Number( $( '.modal-content.show' ).attr( 'data-index' ) ) + 1

                if ( nextIndex > memberModals.length ) {
                    nextIndex = 1
                }

                memberModals.each( function () {
                    $( this ).removeClass( 'show' )
                })

                $( '.modal-content[data-index="' + nextIndex + '"]' ).addClass( 'show' )

            })

        }

    },
    careers: {

        modal: () => {

            // Opening/closing the leadership modal and
            // navigating from one to the other

            const jobsList = $( '.jobs-list' );

            jobsList.on( 'click', '.job-list', function ( ev ) {

                ev.preventDefault();

                const jobsModals = $( '.jobs-list .modal-content' );
                const job = $( this ).attr( 'data-job' );

                var jobTitle = $(this).find(".job-title").get(0);
								var jobLocation = $(this).find(".job-location").get(0);

							  jobTitle = typeof(jobTitle) === "undefined" ? "" : jobTitle.textContent.trim();
								jobLocation = typeof(jobLocation) === "undefined" ? "" : jobLocation.textContent.trim();

								if (jobLocation.indexOf("Locations") !== -1 ){
									jobLocation = $(job).find(".header-info strong").get(0);
									jobLocation = typeof(jobLocation) === "undefined" ? "" : jobLocation.innerHTML.trim();
									jobLocation = jobLocation.replace(/<br>/g,' | ');
								}

								dataLayer.push({
									"event" : "jobDetailsButtonClick",
									"jobTitle" : jobTitle,
									"jobLocation": jobLocation
								});
								$(job).find(".button").click(function(){
									dataLayer.push({
										"event" : "applyNowButtonClick",
										"jobTitle" : jobTitle,
										"jobLocation": jobLocation
									});
								});

                // Hiding the current member modal and showing the
                // the new active member modal before the modal
                // wrapper is shown

                jobsModals.each( function () {
                    $(this).children().removeAttr('style');
                    $(this).children().removeAttr('size');
                    $(this).children().removeAttr('face');
                    jobsModals.removeClass( 'show' );
                });

                $('.jobs-list .modal-content p').each(() => {
                    if( $(this).children().length > 0 ) {
                        var child1 = $(this).children();
                        if ($.trim(child1.text()) === '') {
                            $(this).remove();
                        } else {
                            if(child1.children().lenght > 0) {
                                var child2 = child1.children();
                                if ($.trim(child2.text()) === '') {
                                    $(this).remove();
                                } else {
                                    if (child2.children().lenght > 0) {
                                        var child3 = child2.children();
                                        if ($.trim(child3.text()) === '') {
                                            $(this).remove();
                                        }
                                    }
                                }
                            }
                        }
                    }
                });


                $( job ).addClass( 'show' );

                // Showing the modal wrapper and running equalheight()
                // so that the image and content are vertically
                // aligned

                $( 'body' ).addClass( 'modal-open' );
                Routes.general.equalHeight();

                // Pausing for a split second for the modal is displayed
                // to then fade it in

                setTimeout( () => {
                    $( 'body' ).addClass( 'modal-fade-in' );
                }, 100 );

            });

            // Closing the modal
            $(document).on('keyup', (e) => {

                if (e.keyCode === 27) {
                    $( 'body' ).removeClass( 'modal-fade-in' );
                    setTimeout( () => {
                        $( 'body' ).removeClass( 'modal-open' );
                    }, 300 )
                }
            });

            jobsList.on( 'click', '.close-modal, .is--black', (e) => {

                // Fading out the modal wrapper
                e.preventDefault();
                $( 'body' ).removeClass( 'modal-fade-in' );

                // Pausing until the fade transition is done to then
                // hide the modal wrapper

                setTimeout( () => {
                    $( 'body' ).removeClass( 'modal-open' );
                }, 300 )

            })

        },

        filterJobs: () => {
            $('input[type="radio"]').on('change', () => {

                const url = document.URL;
                const filter_by = $('input[type="radio"]:checked').val();
                $('.jobs-section').addClass('filter-loading');
                $.ajax({
                    type: 'get',
                    url: url,
                    data: { filterBy: filter_by },
                    success: (data) => {
                        $('.jobs-section').removeClass('filter-loading');
                        $('.jobs-list').html(data);
                    },
                    error: (err) => {
                        console.log(err);
                    }
                });

            });
        }

    },
    contact: {

        contactForm: () => {
            // Initialize Select2
            const formSelect = $(".form #topic");

            formSelect.select2({
                minimumResultsForSearch: -1,
                width: '100%'
            });

            // Using new text mask package
            let phoneInput = document.querySelector('.mask-phone');
            let maskOptions = {
                mask: '(#00) 000-0000',
                definitions: {
                    '#': /[1-9]/
                },
                lazy: true
            };
            let maskedInputController = IMask(phoneInput, maskOptions);

            phoneInput.addEventListener('focus', () => {
                maskedInputController.updateOptions({ lazy: false });
            });

            phoneInput.addEventListener('blur', () => {
                maskedInputController.updateOptions({ lazy: true });
            });

            grecaptcha.ready(function() {
            	grecaptcha.execute('6LfXpNMUAAAAAOTWuJjgcBwnVuXx5gCNx-mqvcHU', {action:'contact'}).then(function(token) {
            		$('.form').prepend('<input type="hidden" name="captcha_token" value="' + token + '">');
            	});
            });

            let fullname = document.getElementById('fullname')

            fullname.addEventListener('keypress', function(e) {
                if( !Routes.general.onlyAlphabets(e, this, false)) {
                    e.preventDefault();
                }
            });
            // Using ajax for the form submission

            const form = $( '.form' )

            form.submit( function ( ev ) {

                ev.preventDefault()
                const thisForm = $( this )
                const button = thisForm.find( '.button' )
                const data = thisForm.serialize() + '&' + $.param( { 'action': 'send_form' } )

                thisForm.find( '.error-message' ).hide()
                thisForm.find( '.success-message' ).hide()
                button.addClass('disabled')

                $.post( tz_object.ajax_url, data, response => {

                    if ( response.error === 0 ) {

                        thisForm.find( 'input[type="email"]' ).val( '' )
                        thisForm.find( 'input[type="text"]' ).val( '' )
                        thisForm.find( 'input[type="tel"]' ).val( '' )
                        thisForm.find( 'textarea' ).val( '' )

                        // Hides loader and shows text after the ajax call
                        // has been processed

                        button.removeClass( 'disabled' )

                        let modal = document.getElementById('thank-you');
                        let span = document.getElementsByClassName("close")[0];
                        document.querySelector('#thank-you #response').innerHTML = response.message;
                        modal.style.display = "block";
                        span.onclick = function() {
                            modal.style.display = "none";
                        }
                        window.onclick = function(event) {
                            if (event.target == modal) {
                                modal.style.display = "none";
                            }
                        }

                    } else if ( response.error === 1  ) {
                        // There are missing fields so hide the loader and show
                        // the error message
                        button.removeClass( 'disabled' )
                        thisForm.append( '<p class="error-message">' + response.message + '</p>' )
                    }
                }, 'json' )
            })

        }

    },
	  workWithUs: {
			load: () => {
				$('.hero-content .button').click(function(){
					dataLayer.push({
						"event" : "openPositionsButtonClick",
						"buttonPosition" : "Top"
					});
				});
				$('.apply .button').click(function(){
					dataLayer.push({
						"event" : "openPositionsButtonClick",
						"buttonPosition" : "Bottom"
					});
				});
			}
		},
    jobFairForm: {

        contactFairForm: () => {

						const formSelect = $(".form #topic");

						formSelect.select2({
								minimumResultsForSearch: -1,
								width: '100%'
						});

						let phoneInput = document.querySelector('.mask-phone');
						let maskOptions = {
								mask: '(#00) 000-0000',
								definitions: {
										'#': /[1-9]/
								},
								lazy: true
						};
						let maskedInputController = IMask(phoneInput, maskOptions);

						phoneInput.addEventListener('focus', () => {
								maskedInputController.updateOptions({ lazy: false });
						});

						phoneInput.addEventListener('blur', () => {
								maskedInputController.updateOptions({ lazy: true });
            });

            let onlyLetters = document.querySelectorAll('.alphabetic')
            NodeList.prototype.forEach = Array.prototype.forEach;

            onlyLetters.forEach(function(el) {
                el.addEventListener('keypress', function(e) {
                    if( !Routes.general.onlyAlphabets(e, this, true)) {
                        e.preventDefault();
                    }
                });
            });
            // Using ajax for the form submission

            const form = $( '.form' )

            form.submit( function ( ev ) {

                ev.preventDefault()
                const thisForm = $( this )
                const submitWrap = thisForm.find( '.submit-wrap' )
                const data = thisForm.serialize() + '&' + $.param( { 'action': 'send_fair_form' } )

                thisForm.find( '.error-message' ).hide()
                thisForm.find( '.success-message' ).hide()
                submitWrap.addClass( 'loading' )

                $.post( tz_object.ajax_url, data, response => {

                    if ( response.error === 0 ) {

                        thisForm.find( 'input[type="email"]' ).val( '' )
                        thisForm.find( 'input[type="text"]' ).val( '' )
                        thisForm.find( 'input[type="tel"]' ).val( '' )
                        thisForm.find( 'textarea' ).val( '' )

                        // Hides loader and shows text after the ajax call
                        // has been processed

                        submitWrap.removeClass( 'loading' )

                        var modal = document.getElementById('thank-you');
                        var span = document.getElementsByClassName("close")[0];
                        modal.style.display = "block";
                        span.onclick = function() {
                            modal.style.display = "none";
                        }
                        window.onclick = function(event) {
                        if (event.target == modal) {
                            modal.style.display = "none";
                            }
                        }
                    } else if ( response.error === 1 && response.missing_fields.length !== 0 ) {

                        // There are missing fields so hide the loader and show
                        // the error message

                        submitWrap.removeClass( 'loading' )
                        thisForm.append( '<p class="error-message">' + response.message + '</p>' )

                    } else if ( response.error === 1 && response.missing_fields.length === 0 ) {

                        // Something happend and we don't know what, so hide
                        // the loader and show the error message

                        submitWrap.removeClass( 'loading' )
                        thisForm.append( '<p class="error-message">' + response.message + '</p>' )

                    }

                }, 'json' )

            })

        }

    }

 }


/* ==============================================================================================
 * 3. Initialization
 *
 * Running the router that will run the methods for the general route and the current page
 * route
 * ============================================================================================== */

    $( document ).ready( () => {
        Router.loadEvents()
    })

})( window, document, jQuery )
